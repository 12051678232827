import React from "react";
import { Container } from "react-bootstrap";
import { renderDynamicComponents } from "../components";
import Layout from "../modules/layout";
import SEO from "../modules/seo";
import MicrocopyContext from "../context/microcopy/microcopyContext";
import RegistrationContext from "../context/registration/registrationContext";
import Link from "../helper/link";
import { getValue } from "../helper/translation";

const ConfirmationModal = ({onClose, microcopy}) => {

  return (
  <div className="registration-confirmation-modal-outter">
    <div className="registration-confirmation-modal">
      <div className="header">
        <img
          src="/check-green.svg"
          alt="check-icon"
          className="check-icon"
        />
        <h3>{getValue("label.registrationConfirmationHeadline", microcopy)}</h3>
        <img
          src="/green-cross.svg"
          alt="cross-icon"
          className="close-confirmation"
          onClick={() => onClose(false)}
          role="button"
        />
      </div>
      <p className="text">{getValue("label.registrationConfirmationText", microcopy)}</p>
      <Link
        to="/"
        className="button button--primary"
      >
        {getValue("actions.backToHome", microcopy)}
      </Link>
    </div>
  </div>
)}



const EventRegistration = ({ pageContext }) => {
  const { name, contentComponents, node_locale, settings } = pageContext;
  const { microcopy } = settings;
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const hero = [contentComponents[0]];
  const restOfComponents = contentComponents.slice(1);

  return (
    <Layout siteSettings={settings}>
      <SEO
        title={`${settings.siteName} - ${name}`}
        description={settings.siteDescription}
        author={settings.siteAuthor}
        ogImage={hero[0]?.image?.image?.fluid.src}
      />
      {hero && renderDynamicComponents(hero, node_locale)}
      <MicrocopyContext.Provider value={microcopy}>
        <RegistrationContext.Provider value={setShowConfirmation}>
          <div className="event-registration-outter-container">
            <Container fluid className="d-flex py-5 event-registration-container">
              {restOfComponents &&
                renderDynamicComponents(restOfComponents, node_locale)}
            </Container>
            {showConfirmation && 
              <ConfirmationModal onClose={setShowConfirmation} microcopy={microcopy}/>
            }
          </div>
        </RegistrationContext.Provider>
      </MicrocopyContext.Provider>
    </Layout>
  );
};

export default EventRegistration;
